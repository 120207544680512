import React, { useState } from "react";
import { TextField, Typography, Button, Paper, Box, Autocomplete } from "@mui/material";
import { useFetchCharityQuery, useInternalTransferMutation } from "../store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../components/shared/Loader";

const DonateToCharity = () => {
  const token = useSelector((state) => state.user.token);
  const [charityName, setCharityName] = useState("");
  const [selectedCharity, setSelectedCharity] = useState(null);
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const payload = { clientID: client?.ID, token };
  //const { data: balance, isError: balanceError, error } = useFetchClientsBalanceQuery(payload);
  const balance = useSelector((state) => state.user.balance);
  const {
    data: charities,
    isError: isCharityError,
    isSuccess,
    error: charityError,
    isFetching,
    isLoading,
  } = useFetchCharityQuery(token);
  const [internalTransfer] = useInternalTransferMutation();
  const filteredCharities = charities?.data?.filter((charity) =>
    charity.Accounts_Name.toLowerCase().includes(charityName.toLowerCase())
  );

  const handleCharitySelect = (event, newValue) => {
    setSelectedCharity(newValue);
  };

  const handleSubmit = async () => {
    if (!selectedCharity) {
      toast.error("Please select a charity");
      return;
    }

    if (amount > balance) {
      toast.error("Insufficient balance");
      return;
    }

    setIsSubmitting(true);
    const res = await internalTransfer({ toID: selectedCharity.Accounts_ID, amount, note, ...payload });
    setIsSubmitting(false);
    if (res?.data) {
      toast.success(res.data.message);
      setAmount("");
      setNote("");
      setSelectedCharity(null);
    } else {
      toast.error(res.error.data.message);
    }
  };

  if (isLoading || isSubmitting) {
    return <Loader />;
  }

  return (
    <section
      style={{
        height: "92vh",
        width: "100%",
        backgroundColor: "#f0f2f5",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper elevation={3} sx={{ p: 4, width: { xs: "100%", md: "50%" } }}>
        <Typography variant="h4" gutterBottom>
          Donate to a Charity
        </Typography>
        <Autocomplete
          options={filteredCharities || []}
          getOptionLabel={(option) => option.Accounts_Name}
          onChange={handleCharitySelect}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Charity Name"
              variant="outlined"
              margin="normal"
              value={charityName}
              onChange={(e) => setCharityName(e.target.value)}
            />
          )}
          loading={isFetching}
          renderOption={(props, option) => (
            <li {...props} key={option.Accounts_ID}>
              {option.Accounts_Name}
            </li>
          )}
        />
        {
          selectedCharity && (
            <Box mt={2} mb={2}>
              <Typography variant="h5">{selectedCharity.Accounts_Name}</Typography>
              <Typography variant="body1">{selectedCharity.Accounts_Address}</Typography>
              <Typography variant="body2">{selectedCharity.Accounts_City}</Typography>
              <Typography variant="caption">{selectedCharity.Accounts_CharityNo}</Typography>
            </Box>
          )
        }
        <TextField
          fullWidth
          type="number"
          label="Donation Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          variant="outlined"
          margin="normal"
        />
        <TextField
          fullWidth
          type="text"
          label="Note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          variant="outlined"
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth sx={{ mt: 2 }}>
          Donate
        </Button>
      </Paper >
    </section >
  );
};

export default DonateToCharity;
