
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router } from "react-router-dom";
import { store, persistor } from "./store";
import App from "./App";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/en-gb";
import "react-toastify/dist/ReactToastify.css";

// Apply global styles
const globalStyles = `
  body, html, #root {
    margin: 0;
    padding: 0;
  }
`;
const styleTag = document.createElement("style");
styleTag.type = "text/css";
styleTag.appendChild(document.createTextNode(globalStyles));
document.head.appendChild(styleTag);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
        <Router>
          <App />
        </Router>
      </LocalizationProvider>
    </PersistGate>
  </Provider>
);

































// import React from "react";
// import ReactDOM from "react-dom/client";
// import { Provider } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
// import { store, persistor } from "./store";
// import App from "./App";

// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import "dayjs/locale/en-gb";

// // Apply global styles
// const globalStyles = `
//   body, html, #root {
//     margin: 0;
//     padding: 0;
//   }
// `;
// // Create a style tag and append the global styles
// const styleTag = document.createElement("style");
// styleTag.type = "text/css";
// styleTag.appendChild(document.createTextNode(globalStyles));
// document.head.appendChild(styleTag);

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <Provider store={store}>
//     <PersistGate loading={null} persistor={persistor}>
//       <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
//         <App />
//       </LocalizationProvider>
//     </PersistGate>
//   </Provider>
// );
