import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useMemo } from "react";

function FilterableTable({ data, columns, options, initialState, state }) {
  options = {
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      density: "compact",
      ...initialState,
      pagination: { pageSize: 15 },
    },
    enableColumnFilterModes: true,
    enableHiding: false,
    enableFullScreenToggle: false,
    columnFilterDisplayMode: "popover",
    positionActionsColumn: "last",
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid rgba(81, 81, 81, .5)",
        "& .MuiSvgIcon-root": {
          color: "#665a5a !important",
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#263773",
        color: "white",
        fontWeight: "bold",
        "& .MuiSvgIcon-root": {
          color: "white !important",
        },
      },
    },
    muiTableHeadProps: {
      sx: {
        border: "1px solid rgba(81, 81, 81, .5)",
        "& .MuiSvgIcon-root": {
          color: "white !important",
        },
      },
    },
    muiTableProps: {
      sx: {
        border: "1px solid rgba(81, 81, 81, .5)",
        "& .MuiSvgIcon-root": {
          color: "white !important",
        },
      },
    },
    ...options,
  };

  columns = useMemo(() => columns, [columns]);
  const table = useMaterialReactTable({
    data,
    columns,
    ...options,
    state,
  });

  return <MaterialReactTable table={table} />;
}

export default FilterableTable;
