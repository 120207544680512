import React from "react";
import { Bars } from "react-loader-spinner";

export default function Loader() {
    return (
        <div
            style={{ height: "90vh", display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}
        >
            <Bars
                height="80"
                width="80"
                color="#3C76D2"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperclassName=""
                visible={true}
            />
        </div>

    );
}
