import React from 'react';
import { Button, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

const FileUploader = ({ onFileUpload, handleStoreFile, setTryUpload }) => {

  const handleFileChange = (event) => {
    setTryUpload(true)
    const file = event.target.files[0];
    if (file) {
      onFileUpload(file);
    }
  };

  return (
    <div className="file-uploader">
      <Typography variant="h6">Upload Document (CSV, PDF, or JPEG)</Typography>
      <input
        accept=".csv,application/pdf, image/jpeg"
        style={{ display: 'none' }}
        id="file-upload"
        type="file"
        onChange={handleFileChange}
      />
      <div style={{ display: "flex", gap: "10px" }}>
        <label htmlFor="file-upload">
          <Button
            variant="contained"
            color="primary"
            component="span"
            startIcon={<CloudUploadIcon />}
            className="upload-button"
          >
            Select
          </Button>
        </label>
        <Button
          variant="contained"
          color="primary"
          component="span"
          startIcon={<SaveOutlinedIcon />}
          className="upload-button"
          onClick={handleStoreFile}
        >
          Upload
        </Button>
      </div>
    </div>
  );
};

export default FileUploader;





