import { useNavigate, Link } from "react-router-dom";
import { useState, useCallback, useMemo } from "react";
import { useFormik } from "formik";
import { TextField, IconButton, Button, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import styles from './css/LoginForm.module.css'
import { successLogin, useForgotPasswordMutation, useLoginByPasswordMutation } from "../../../store";
import Loader from "../../shared/Loader";
import GoogleLoginBtn from "../../button/GoogleLoginBtn";
import { loginSchema } from "../../../Validations/LoginValidations";

function LoginForm() {

  const [LoginByPassword, LoginByPasswordResult] = useLoginByPasswordMutation();
  const [ForgotPassword] = useForgotPasswordMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Handlers
  const onSubmit = useCallback(async (values, actions) => {
    const res = await LoginByPassword(values);
    if (res.error) {
      toast.error("Failed to login: " + res.error.data.message);
    } else {
      dispatch(successLogin({
        user: res?.data?.data.user,
        token: res?.data?.data.token.token,
        expiresIn: res?.data?.data.token.expiresIn
      }));
      toast.success("Welcome back.");
      navigate("/account");
    }
    actions.resetForm();
  }, [LoginByPassword, dispatch, navigate]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit,
  });

  const handleForgotPassword = useCallback(async () => {
    if (!values.email) return;
    const res = await ForgotPassword({ email: values.email });
    if (res.error) {
      toast.error("Failed to send recovery email: " + res.error.status);
    } else {
      toast.success("Recovery email sent");
      navigate("/confirm-mail");
    }
  }, [ForgotPassword, values.email, navigate]);

  const handleClickShowPassword = useCallback(() => setShowPassword((show) => !show), []);
  const handleMouseDownPassword = useCallback((event) => event.preventDefault(), []);

  // State
  const [showPassword, setShowPassword] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  // Memoized Elements
  const emailField = useMemo(() => (
    <TextField
      required
      error={!!errors.email && touched.email}
      label="Email"
      type="email"
      id="email"
      value={values.email}
      helperText={errors.email && touched.email ? errors.email : ""}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  ), [errors.email, touched.email, values.email, handleBlur, handleChange]);

  const passwordField = useMemo(() => (
    <TextField
      required
      error={!!errors.password && touched.password}
      label="Password"
      type={showPassword ? "text" : "password"}
      id="password"
      value={values.password}
      helperText={errors.password && touched.password ? errors.password : ""}
      onBlur={handleBlur}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  ), [errors.password, touched.password, values.password, showPassword, handleClickShowPassword, handleMouseDownPassword, handleBlur, handleChange]);

  if (LoginByPasswordResult.isLoading) return <Loader />;

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit} autoComplete="off">
      {emailField}
      {!isForgotPassword ? (
        <>
          {passwordField}
          <Button variant="contained" type="submit" disabled={isSubmitting}>
            Submit
          </Button>
        </>
      ) : (
        <>
          <Button variant="contained" color="primary" onClick={handleForgotPassword} disabled={!values.email}>
            Send password recovery email
          </Button>
          <Button variant="contained" color="warning" onClick={() => setIsForgotPassword(false)}>
            Cancel
          </Button>
        </>
      )}
      {!isForgotPassword && <Link onClick={() => setIsForgotPassword(true)}>Forgot Password?</Link>}
      <GoogleLoginBtn />
    </form>
  );
}

export default LoginForm;
