import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Paper, Typography, List, ListItem, ListItemText, IconButton, Divider } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useDownloadFileQuery, useGetAllFilesQuery } from '../../../store/apis/documentApi';
import Loader from '../../shared/Loader';
import NoRecordFound from '../../shared/NoRecordFound';

const DocumentList = () => {

    const [dataId, setDataId] = useState(null);

    const selectedClient = useSelector((state) => state.user.selectedClient);
    const client = useSelector((state) => state.user.clients[selectedClient]);
    const token = useSelector((state) => state.user.token);

    const { data: documents, isLoading } = useGetAllFilesQuery({ token, clientID: client.ID });
    const { data: downloadedFile, isLoading: downloadedLoading, isSuccess } = useDownloadFileQuery(
        { id: dataId, token, clientID: client.ID },
        { skip: !dataId }
    );

    useEffect(() => {
        if (isSuccess && downloadedFile) {
            const link = document.createElement('a');
            link.href = downloadedFile.downloadUrl;
            link.download = downloadedFile.fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // Reset dataId to null after download
            setDataId(null);
        }
        console.log(dataId)
    }, [isSuccess, downloadedFile, dataId]);

    const handleDownload = (id) => {
        console.log(id)
        setDataId(id);
    };

    if (isLoading || downloadedLoading) {
        return <Loader />;
    }

    return (
        <Container maxWidth="md" className="document-list-container">
            <Typography variant="h5" className="list-title">Uploaded Documents</Typography>
            <Paper className="paper-container">
                <List>
                    {documents?.files?.length ? documents?.files.map((doc, index) => (
                        <React.Fragment key={index}>
                            <ListItem>
                                <ListItemText primary={doc.fileName} secondary={doc.dateAdded.slice(0, 10)} />
                                <IconButton edge="end" onClick={() => handleDownload(doc.id)}>
                                    <DownloadIcon />
                                </IconButton>
                            </ListItem>
                            {index < documents.length - 1 && <Divider />}
                        </React.Fragment>
                    )) : <NoRecordFound />
                    }
                </List>
            </Paper>
        </Container>
    );
};
export default DocumentList;
