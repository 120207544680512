import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import Papa from 'papaparse';

const DocumentViewer = ({ file, tryUload }) => {

  const [csvData, setCsvData] = useState(null);

  useEffect(() => {
    if (file && file.type === 'text/csv') {
      const reader = new FileReader();
      reader.onload = (event) => {
        const text = event.target.result;
        const result = Papa.parse(text, { header: true });
        setCsvData(result.data);
      };
      reader.readAsText(file);
    }
  }, [file]);

  if (!file) {
    return (
      !tryUload ? <Typography variant="body1">No File selected yet.</Typography> : null
    )
  }

  const fileURL = URL.createObjectURL(file);

  if (file.type === 'application/pdf') {
    return (
      <div className="document-viewer">
        <Typography variant="h6">View PDF Document</Typography>
        <embed src={fileURL} type="application/pdf" width="100%" height="600px" />
      </div>
    );
  }

  if (file.type === 'text/csv') {
    return (
      <div className="document-viewer">
        <Typography variant="h6">View CSV Document</Typography>
        <table>
          <thead>
            <tr>
              {csvData && csvData.length > 0 && Object.keys(csvData[0]).map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {csvData && csvData.map((row, index) => (
              <tr key={index}>
                {Object.values(row).map((value, i) => (
                  <td key={i}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  if (file.type.startsWith('image/')) {
    return (
      <div className="document-viewer">
        <Typography variant="h6">View Image</Typography>
        <img src={fileURL} alt="Uploaded preview" style={{ maxWidth: '100%', height: 'auto' }} />
      </div>
    );
  }

  return <Typography variant="body1">Unsupported file type.</Typography>;
};

export default DocumentViewer;

