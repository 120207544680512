import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import banner1 from "../../../assets/home/banner1.png";
import { Button } from "@mui/material";
import "./css/Hero.css";
import { useNavigate } from "react-router-dom";

const Hero = ({ handleContactScroll }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/contact-form");
  };

  return (
    <section>
      <Carousel infiniteLoop stopOnHover={false} autoPlay showThumbs={false}>
        <div style={{ height: "90vh", position: "relative" }}>
          <div className="hero-overlay"></div>
          <img src={banner1} alt="childrens" style={{ height: "90vh", zIndex: "5" }} />
          <div className="hero-content" style={{ marginTop: "30vh" }}>
            <div className="button-container">
              <Button onClick={handleNavigate} variant="contained" size="large">
                Apply NOW
              </Button>
              <Button onClick={handleContactScroll} variant="outlined" size="large">
                CONTACT US
              </Button>
            </div>
          </div>
        </div>
      </Carousel>
    </section>
  );
};

export default Hero;
