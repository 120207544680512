import React, { useState } from 'react';
import {
    Container,
    Box,
    TextField,
    Button,
    Typography,
    Paper,
    Alert,
    InputAdornment,
    IconButton,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useResetPasswordMutation } from '../store';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { resetPasswordSchema } from '../Validations/resetPasswordValidations';
import { toast } from 'react-toastify';

const ResetPassword = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const { token } = useParams();

    const [resetPassword, { isLoading, data }] = useResetPasswordMutation();
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const navigate = useNavigate();
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: resetPasswordSchema,
        onSubmit: (values) => {
            setError('');
            setSuccess(true);
            resetPassword({ token, newPassword: values.newPassword });
            navigate("/login")
            toast.success("Password has been reset successfully.")

        },
    });

    return (
        <Container
            maxWidth="md"
            sx={{ minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <Paper elevation={3} sx={{ padding: 6, width: '80%' }}>
                <Typography variant="h5" gutterBottom>
                    Reset Password
                </Typography>
                {error && <Alert severity="error">{error}</Alert>}
                {success && <Alert severity="success">Password reset successfully!</Alert>}
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                        mt: 2,
                    }}
                >
                    <TextField
                        required
                        error={!!errors.newPassword && touched.newPassword}
                        label="New Password"
                        type={showPassword ? "text" : "password"}
                        id="newPassword"
                        name="newPassword"
                        value={values.newPassword}
                        helperText={errors.newPassword && touched.newPassword ? errors.newPassword : ""}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        required
                        error={!!errors.confirmPassword && touched.confirmPassword}
                        label="Confirm Password"
                        type={showPassword ? "text" : "password"}
                        id="confirmPassword"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        helperText={errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : ""}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button variant="contained" color="primary" type="submit">
                        Reset Password
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
};

export default ResetPassword;
