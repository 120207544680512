import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";

import { userReducer } from "./slices/userSlice";
import { usersApi } from "./apis/usersApi";
import { authApi } from "./apis/authApi";
import { clientsApi } from "./apis/clientsApi";
import { transactionsApi } from "./apis/transactionsApi";
import { supportApi } from "./apis/supportAPI";
import { documentsApi } from "./apis/documentApi";

const persistConfig = {
  key: "root",
  storage,
};
const persistedUserReducer = persistReducer(persistConfig, userReducer);

export const store = configureStore({
  reducer: {
    user: persistedUserReducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [clientsApi.reducerPath]: clientsApi.reducer,
    [transactionsApi.reducerPath]: transactionsApi.reducer,
    [supportApi.reducerPath]: supportApi.reducer,
    [documentsApi.reducerPath]: documentsApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false })
      .concat(usersApi.middleware)
      .concat(authApi.middleware)
      .concat(clientsApi.middleware)
      .concat(transactionsApi.middleware)
      .concat(supportApi.middleware)
      .concat(documentsApi.middleware);
  },
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

export * from "./apis/authApi";
export * from "./apis/usersApi";
export * from "./apis/clientsApi";
export * from "./apis/transactionsApi";
export * from "./slices/userSlice";
export * from "./env";
