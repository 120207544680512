import React from "react";
import "./css/footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="column">
        <h3 className="heading">About Us</h3>
        <p></p>
      </div>
      <div className="column">
        <h3 className="heading">Contact Us</h3>
        <p>Email: office@actmcr.org.uk</p>
        <p>Phone: +44 333 613 4528</p>
      </div>
      <div className="column">
        <h3 className="heading">office</h3>
        <div className="socialIcons">
          <p>Suite 1, 35 Northumberland St. Salford M7 4DR</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
