import React from 'react';
import TransfarFundsComponent from '../components/features/Dashboard/TransfarFundsComponent';

const TransfarFunds = () => {
    return (
        <div style={{width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', height: '92vh', backgroundColor: '#f0f2f5' }}>
            <TransfarFundsComponent />
        </div>
    );
};

export default TransfarFunds;