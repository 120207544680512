import * as Yup from "yup";
const passwordRule = /^(?=.*[\W_])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export const resetPasswordSchema = Yup.object().shape({
    newPassword: Yup
        .string()
        .min(6)
        .matches(passwordRule, {
            message: "Password must be at least 8 characters, 1 uppercase, 1 lowercase, and 1 special character.",
        })
        .required("Required"),
    confirmPassword: Yup
        .string()
        .min(6)
        .matches(passwordRule, {
            message: "Password must be at least 8 characters, 1 uppercase, 1 lowercase, and 1 special character.",
        })
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required("Required"),
});