import * as yup from "yup";


export const supportMessageSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Please enter a valid email").required("Email is required"),
    phoneNumber: yup.string().required("Name is required"),
    message: yup.string().required("Message is required"),
});


export const interestedToOpenAccountSchema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    surname: yup.string().required("Surname is required"),
    phoneNumber: yup.string().required("Phone number is required"),
    email: yup.string().email("Please enter a valid email").required("Email is required"),
});
