import React from "react";
import UserInfo from "../components/features/AccountDetails/UserInfo";

const AccountDetails = () => {
  return (
    <section>
      <UserInfo />
    </section>
  );
};

export default AccountDetails;
