import React from 'react';

const SendRequest = () => {
    return (
        <div style={{minHeight: "100vh"}}>
            <h1>This is Send request page!</h1>
        </div>
    );
};

export default SendRequest;