import React from 'react';
import RequestTopUpComponent from '../components/features/Dashboard/RequestTopUpComponent';

const RequestTopup = () => {
    return (
        <div>
            <RequestTopUpComponent />
        </div>
    );
};

export default RequestTopup;