import { useParams, useNavigate } from "react-router-dom";
import { useValidateEmailQuery, successLogin } from "../store";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Loader from "../components/shared/Loader";

const EmailVerificationPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, error, isError, isLoading, isSuccess } = useValidateEmailQuery(token);

  if (isSuccess) {
    toast.success(data.message);
    dispatch(successLogin(data));
    navigate("/account");
  } else if (error) {
    toast.error(error.data.message);
    navigate("/login");
  }
  return (
    <div>
      {/* {error && <div>Verification failed. Please try again or contact the office.</div>} */}
      {isLoading && <Loader />}
    </div>
  );
};

export default EmailVerificationPage;
