import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const usersApi = createApi({
  reducerPath: "users",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/v1",
  }),
  endpoints(builder) {
    return {
      fetchUsers: builder.query({
        query: () => ({
          url: "/users",
          method: "GET",
        }),
      }),
    };
  },
});

export const { useFetchUsersQuery } = usersApi;
export { usersApi };
