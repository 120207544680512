import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';

const NoRecordFound = ({ message = "No records found", onRetry }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
            padding={4}
            textAlign="center"
        >
            <SearchOffIcon style={{ fontSize: 80, color: '#b0bec5' }} />
            <Typography variant="h6" color="textSecondary" gutterBottom>
                {message}
            </Typography>
            {onRetry && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onRetry}
                    sx={{ mt: 2 }}
                >
                    Retry
                </Button>
            )}
        </Box>
    );
};

export default NoRecordFound;
