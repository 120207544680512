import { useState, useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { googleClientID } from "../store";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import LoginForm from "../components/features/Authentication/LoginForm";
import SignupForm from "../components/features/Authentication/SignupForm";

function LoginPage() {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();

  const isAuth = useSelector((state) => state.user.isAuthenticated);
  useEffect(() => {
    // Redirect to dashboard if already signed in
    if (isAuth) {
      navigate("/account");
    }
  }, [isAuth, navigate]);

  return (
    <GoogleOAuthProvider clientId={googleClientID}>
      <Box
        sx={{
          width: "80%",
          typography: "body1",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
          minHeight: "100vh"
        }}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="login and signin">
              <Tab label="Login" value="1" />
              <Tab label="Signup" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <LoginForm />
          </TabPanel>
          <TabPanel value="2">
            <SignupForm />
          </TabPanel>
        </TabContext>
      </Box>
    </GoogleOAuthProvider>
  );
}
export default LoginPage;
