import { useRef } from "react";
import Contact from "../components/features/Home/Contact";
import DonorSection from "../components/features/Home/DonorSection";
import FoundriesSecton from "../components/features/Home/FoundriesSecton";
import Hero from "../components/features/Home/Hero";
import Recipient from "../components/features/Home/Recipient";
import About from "../components/features/Home/About";

function HomePage() {
  const contactRef = useRef(null);
  const handleContactScroll = () => {
    contactRef?.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <Hero handleContactScroll={handleContactScroll} />
      <About />
      {/* <DonorSection />
      <FoundriesSecton />
      <Recipient /> */}
      <Contact contactRef={contactRef} />
    </div>
  );
}

export default HomePage;
