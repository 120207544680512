import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const supportApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1/",
    }),
    endpoints(builder) {
        return {
            contactWithSupport: builder.mutation({
                query: (data) => ({
                    url: "/support",
                    method: "POST",
                    body: data,
                }),
            }),

            interestedToOpenAccount: builder.mutation({
                query: (data) => ({
                    url: "/support/interest-to-open-account",
                    method: "POST",
                    body: data,
                }),
            }),
        };
    },
});

export const {
    useContactWithSupportMutation,
    useInterestedToOpenAccountMutation
} = supportApi;

export { supportApi };
