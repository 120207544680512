import React, { useEffect, useState } from 'react';
import './css/CustomTable.css';
import { Popover, TextField, Tooltip } from '@mui/material';
import { FilterList, SyncAlt, DoDisturbAlt } from '@mui/icons-material';

const CustomTable = ({ columns, data, actions, getTopUpOptions, handleInputChange, handleKeyPress }) => {

    const [filteredData, setFilteredData] = useState(data);
    const [sortDirection, setSortDirection] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeFilterKey, setActiveFilterKey] = useState('');
    const [filterText, setFilterText] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(15);

    // Use Effect to reset filteredData when data changes
    useEffect(() => {
        setFilteredData([...data]);
    }, [data]);

    // Handle sorting
    const handleSort = (key) => {
        const currentDirection = sortDirection[key] || 'asc';
        const direction = currentDirection === 'asc' ? 'desc' : 'asc';

        const sortedData = [...data].sort((a, b) => {
            const aValue = isNaN(a[key]) ? a[key] : parseFloat(a[key]);
            const bValue = isNaN(b[key]) ? b[key] : parseFloat(b[key]);

            if (direction === 'asc') {
                return aValue > bValue ? 1 : -1;
            } else {
                return aValue < bValue ? 1 : -1;
            }
        });

        setFilteredData(sortedData);
        setSortDirection({ [key]: direction });
    };

    // Reset filters and sorting
    const handleReset = () => {
        setFilteredData(data);
        setFilterText('');
        setSortDirection({});
    };

    // Open the popover for filtering
    const handleFilterClick = (event, key) => {
        setAnchorEl(event.currentTarget);
        setActiveFilterKey(key);
    };

    // Close the popover
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Filter as user types and highlight the matched text
    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilterText(value);
        if (value) {
            const filtered = data.filter((row) =>
                row[activeFilterKey].toString().toLowerCase().includes(value.toLowerCase())
            );
            setFilteredData(filtered);
        } else {
            setFilteredData(data);
        }
    };

    // Highlight the matched text in the table
    const highlightText = (text, filter) => {
        if (!filter) return text;

        const regex = new RegExp(`(${filter})`, 'gi');
        const parts = text?.split(regex);
        return parts?.map((part, index) =>
            part?.toLowerCase() === filter?.toLowerCase() ? (
                <span key={index} className="highlight">{part}</span>
            ) : (
                part
            )
        );
    };

    // Pagination logic
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);
    const isPopoverOpen = Boolean(anchorEl);

    return (
        <div className="table-wrapper">
            <table className="custom-table">
                <thead>
                    <tr>
                        {columns.map((column) => (
                            <th key={column.key}>
                                <div className='custom-table-column'>
                                    {column.label}
                                    <div className="header-icons">
                                        <Tooltip title="Filter">
                                            <FilterList fontSize="small" onClick={(e) => handleFilterClick(e, column.key)} />
                                        </Tooltip>
                                        <Tooltip title="Sort">
                                            <SyncAlt fontSize="small" onClick={() => handleSort(column.key)} className='rotate' />
                                        </Tooltip>
                                        <Tooltip title="Reset">
                                            <DoDisturbAlt fontSize="small" onClick={() => handleReset()} />
                                        </Tooltip>
                                    </div>
                                </div>
                            </th>
                        ))}
                        {actions && <th>Actions</th>}
                    </tr>
                </thead>
                <tbody>
                    {currentRows.map((row) => (
                        <tr key={row.ID}>
                            {columns.map((column) => (
                                <td key={column.key}>
                                    {column.key === 'paymentMethod' ? (
                                        <select
                                            value={row.paymentMethod || ''}
                                            onChange={(e) => handleInputChange(e, row.ID, column.key)}
                                            className="custom-select"
                                        >
                                            {getTopUpOptions(row.CardStatus)}
                                        </select>
                                    ) : column.key === 'amount' ? (
                                        <input
                                            type="text"
                                            value={row.amount || ''}
                                            onChange={(e) => handleInputChange(e, row.ID, column.key)}
                                            placeholder="Amount"
                                            className="custom-input"
                                            onKeyPress={handleKeyPress}
                                        />
                                    ) : (
                                        highlightText(row[column?.key]?.toString(), filterText)
                                    )}
                                </td>
                            ))}
                            {actions && (
                                <td className="actions-column">
                                    {actions.map((action, index) => (
                                        <button
                                            key={index}
                                            className={`action-btn ${action.className}`}
                                            onClick={() => action.onClick(row)}
                                        >
                                            {action.icon}
                                        </button>
                                    ))}
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination */}
            {filteredData.length > rowsPerPage && (
                <div className="pagination">
                    <button
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                        className="page-button"
                    >
                        First
                    </button>
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="page-button"
                    >
                        &laquo;
                    </button>
                    {[...Array(totalPages)].map((_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="page-button"
                    >
                        &raquo;
                    </button>
                    <button
                        onClick={() => handlePageChange(totalPages)}
                        disabled={currentPage === totalPages}
                        className="page-button"
                    >
                        Last
                    </button>
                </div>
            )}

            <Popover
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <div>
                    <TextField label="Filter" onChange={handleFilterChange} />
                </div>
            </Popover>
        </div>
    );
};

export default CustomTable;





























































// import React, { useEffect, useState } from 'react';
// import './css/CustomTable.css';
// import { Popover, TextField, Tooltip } from '@mui/material';
// import {
//     FilterList, SyncAlt, DoDisturbAlt
// } from '@mui/icons-material';
// const CustomTable = ({ columns, data, actions, getTopUpOptions, handleInputChange, handleKeyPress }) => {

//     const [filteredData, setFilteredData] = useState(data);
//     const [sortDirection, setSortDirection] = useState({});
//     const [anchorEl, setAnchorEl] = useState(null);
//     const [activeFilterKey, setActiveFilterKey] = useState('');
//     const [filterText, setFilterText] = useState('');

//     // Use Effect to reset filteredData when data changes
//     useEffect(() => {
//         setFilteredData([...data]);
//     }, [data]);

//     // Handle sorting
//     const handleSort = (key) => {
//         const currentDirection = sortDirection[key] || 'asc';
//         const direction = currentDirection === 'asc' ? 'desc' : 'asc';

//         const sortedData = [...data].sort((a, b) => {
//             const aValue = isNaN(a[key]) ? a[key] : parseFloat(a[key]);
//             const bValue = isNaN(b[key]) ? b[key] : parseFloat(b[key]);

//             if (direction === 'asc') {
//                 return aValue > bValue ? 1 : -1;
//             } else {
//                 return aValue < bValue ? 1 : -1;
//             }
//         });

//         setFilteredData(sortedData);
//         setSortDirection({ [key]: direction });
//     };

//     // Reset filters and sorting
//     const handleReset = () => {
//         setFilteredData(data);
//         setFilterText('');
//         setSortDirection({});
//     };

//     // Open the popover for filtering
//     const handleFilterClick = (event, key) => {
//         setAnchorEl(event.currentTarget);
//         setActiveFilterKey(key);
//     };

//     // Close the popover
//     const handleClose = () => {
//         setAnchorEl(null);
//     };

//     // Filter as user types and highlight the matched text
//     const handleFilterChange = (event) => {
//         const value = event.target.value;
//         setFilterText(value);
//         if (value) {
//             const filtered = data.filter((row) =>
//                 row[activeFilterKey].toString().toLowerCase().includes(value.toLowerCase())
//             );
//             setFilteredData(filtered);
//         } else {
//             setFilteredData(data);
//         }
//     };

//     // Highlight the matched text in the table
//     const highlightText = (text, filter) => {
//         if (!filter) return text;

//         const regex = new RegExp(`(${filter})`, 'gi');
//         const parts = text?.split(regex);
//         return parts?.map((part, index) =>
//             part?.toLowerCase() === filter?.toLowerCase() ? (
//                 <span key={index} className="highlight">{part}</span>
//             ) : (
//                 part
//             )
//         );
//     };

//     const isPopoverOpen = Boolean(anchorEl);

//     return (
//         <div className="table-wrapper">
//             <table className="custom-table">
//                 <thead>
//                     <tr>
//                         {columns.map((column) => (
//                             <th key={column.key}>
//                                 <div className='custom-table-column'>
//                                     {column.label}
//                                     <div className="header-icons">
//                                         <Tooltip title="Filter">
//                                             <FilterList fontSize="small" onClick={(e) => handleFilterClick(e, column.key)} />
//                                         </Tooltip>
//                                         <Tooltip title="Sort">
//                                             <SyncAlt fontSize="small" onClick={() => handleSort(column.key)} className='rotate' />
//                                         </Tooltip>
//                                         <Tooltip title="Reset">
//                                             <DoDisturbAlt fontSize="small" onClick={() => handleReset()} />
//                                         </Tooltip>
//                                     </div>
//                                 </div>
//                             </th>
//                         ))}
//                         {actions && <th>Actions</th>}
//                     </tr>
//                 </thead>
//                 <tbody>

//                     {filteredData?.map((row) => (
//                         <tr key={row.ID}>
//                             {columns.map((column) => (
//                                 <td key={column.key}>
//                                     {column.key === 'paymentMethod' ? (
//                                         <select
//                                             value={row.paymentMethod || ''}
//                                             onChange={(e) => handleInputChange(e, row.ID, column.key)}
//                                             className="custom-select"
//                                         >
//                                             {getTopUpOptions(row.CardStatus)}
//                                         </select>
//                                     ) : column.key === 'amount' ? (
//                                         <input
//                                             type="text"
//                                             value={row.amount || ''}
//                                             onChange={(e) => handleInputChange(e, row.ID, column.key)}
//                                             placeholder="Amount"
//                                             className="custom-input"
//                                             onKeyPress={handleKeyPress}
//                                         />
//                                     ) : (
//                                         // row[column.key]
//                                         highlightText(row[column?.key]?.toString(), filterText)
//                                     )}
//                                 </td>
//                             ))}
//                             {
//                                 actions && <td className="actions-column">
//                                     {actions && actions.map((action, index) => (
//                                         <button
//                                             key={index}
//                                             className={`action-btn ${action.className}`}
//                                             onClick={() => action.onClick(row)}
//                                         >
//                                             {action.icon}
//                                         </button>
//                                     ))}
//                                 </td>
//                             }
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//             <Popover
//                 open={isPopoverOpen}
//                 anchorEl={anchorEl}
//                 onClose={handleClose}
//             >
//                 <div>
//                     <TextField label="Filter" onChange={handleFilterChange} />
//                 </div>
//             </Popover>
//         </div>
//     );
// };

// export default CustomTable;



