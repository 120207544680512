import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const documentsApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1/",
    }),
    tagTypes: ['Files'],
    endpoints(builder) {
        return {
            uploadDoc: builder.mutation({
                query: (payload) => {
                    const { fileData, token, clientID } = payload || {};
                    return {
                        url: `/files/upload?clientID=${clientID}`,
                        method: 'POST',
                        headers: { Authorization: `Bearer ${token}` },
                        body: fileData,
                    }
                },
                invalidatesTags: ['Files'],
            }),

            getAllFiles: builder.query({
                query: (payload) => {
                    const { token, clientID } = payload || {};
                    return {
                        url: `/files/all-files?clientID=${clientID}`,
                        method: 'GET',
                        headers: { Authorization: `Bearer ${token}` },
                    }
                },
                providesTags: ['Files'],
            }),

            downloadFile: builder.query({
                query: (payload) => {
                    const { id, token, clientID } = payload || {};
                    return {
                        url: `/files/file/${id}?clientID=${clientID}`,
                        method: 'GET',
                        headers: { Authorization: `Bearer ${token}` },
                    }
                },
            })
        };
    },
});

export const {
    useUploadDocMutation,
    useGetAllFilesQuery,
    useDownloadFileQuery
} = documentsApi;

export { documentsApi };
