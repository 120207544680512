import React from 'react';
import NotFound from '../components/shared/NotFound';

const NotFoundPage = () => {
    return (
        <NotFound />
    );
}

export default NotFoundPage;
