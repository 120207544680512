import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  token: null,
  tokenExpiration: null,
  userObj: {},
  clients: {},
  coilelID: null,
  selectedClient: 0,
  balance: 0,
};

export const checkTokenExpiration = createAsyncThunk(
  'user/checkTokenExpiration',
  (_, { getState, dispatch }) => {
    const state = getState();
    const tokenExpiration = state.user.tokenExpiration;
    if (Date.now() >= tokenExpiration) {
      dispatch(successLogout());
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    successLogin(state, action) {
      state.isAuthenticated = true;
      state.userObj = action.payload.user;
      state.token = action.payload.token;
      const expiresIn = Number(action.payload.expiresIn);
      if (isNaN(expiresIn)) {
        return;
      }
      state.tokenExpiration = Date.now() + expiresIn * 1000;
    },
    successLogout(state) {
      return initialState;
    },
    setUsersClients(state, action) {
      const clients = action.payload;
      const selectedClient = state.selectedClient;
      const coilelID =
        clients[selectedClient].MobileAbroad?.length === 2 || clients[selectedClient].MobileAbroad?.length === 3
          ? clients[selectedClient].MobileAbroad
          : null;
      state.clients = clients;
      state.coilelID = coilelID;
    },
    setSelectedClient(state, action) {
      const selectedClient = action.payload;
      const client = state.clients[selectedClient];
      const coilelID =
        client.MobileAbroad?.length === 2 || client.MobileAbroad?.length === 3 ? client.MobileAbroad : null;
      state.selectedClient = selectedClient;
      state.coilelID = coilelID;
    },
    setBalance(state, action) {
      state.balance = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkTokenExpiration.fulfilled, (state, action) => {
      // No additional state updates needed, handled by successLogout
    });
  },
});

export const { successLogin, successLogout, setUsersClients, setSelectedClient, setBalance } = userSlice.actions;
export const userReducer = userSlice.reducer;
