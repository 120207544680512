import React from "react";
import "./css/notFound.css";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1 className="not-found-heading">404</h1>
        <h2 className="not-found-subheading">Oops! Page Not Found</h2>
        <p className="not-found-text">this page will be live in due course</p>
        <button className="back-to-home-btn" onClick={() => navigate("/")}>
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default NotFound;
