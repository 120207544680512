import React from 'react';
import { Container, Typography, Box, Button, Grid, Card, CardContent } from '@mui/material';
import styles from './css/About.module.css';
import logo from "../../../assets/logo.jpg";

const About = () => {
    return (
        <section style={{backgroundColor: "#f5f5fa", paddingTop: "20px"}}>
            <Container className={styles.root}>
                <Box className={styles.content}>
                    <img src={logo} alt="ACT Logo" className={styles.logo} />
                    <Typography variant="h4" gutterBottom>
                        Join hands with <span className={styles.highlight}>ACT</span> to supercharge your donations and fundraising.
                    </Typography>
                    <Typography variant="body1">
                        Our extensive range of services provides you with the tools and support you need to streamline your donations and maximize your impact.
                    </Typography>
                </Box>

                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <Card className={styles.card}>
                            <CardContent className={styles.cardContent}>
                                <Typography variant="h6" gutterBottom className={styles.cardTitle}>
                                    Our Mission
                                </Typography>
                                <Typography variant="body2">
                                    We aim to empower individuals and organizations by providing innovative solutions for fundraising and donations.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card className={styles.card}>
                            <CardContent className={styles.cardContent}>
                                <Typography variant="h6" gutterBottom className={styles.cardTitle}>
                                    Our Vision
                                </Typography>
                                <Typography variant="body2">
                                    A world where every donation and fundraising effort is maximized for the greatest impact.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card className={styles.card}>
                            <CardContent className={styles.cardContent}>
                                <Typography variant="h6" gutterBottom className={styles.cardTitle}>
                                    Our Values
                                </Typography>
                                <Typography variant="body2">
                                    Transparency, integrity, and innovation are at the heart of everything we do.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Box textAlign="center" className={styles.button}>
                    <Button variant="contained" color="primary">
                        Learn More
                    </Button>
                </Box>
            </Container>
        </section>
    );
};

export default About;
