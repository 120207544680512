import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setUsersClients } from "../";

const clientsApi = createApi({
  reducerPath: "clients",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/v1/clients",
  }),
  tagTypes: ["clients"],
  endpoints(builder) {
    return {
      fetchUsersClients: builder.query({
        providesTags: ["clients", "LIST"],
        query: (token) => ({
          url: "/",
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(setUsersClients(data.data));
          } catch (error) {
          }
        },
      }),
      fetchCoilelClients: builder.query({
        query: (payload) => ({
          url: `/coilelClients?clientID=${payload.clientID}&coilelID=${payload.coilelID}`,
          method: "GET",
          headers: { Authorization: `Bearer ${payload.token}` },
        }),
      }),
      fetchCharity: builder.query({
        query: (token) => ({
          url: `/charities`,
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }),
      }),
      fetchClientByID: builder.query({
        query: (payload) => ({
          url: `/${payload.id}`,
          method: "GET",
          headers: { Authorization: `Bearer ${payload.token}` },
        }),
      }),
      updateClient: builder.mutation({
        invalidatesTags: [{ type: "clients", id: "LIST" }],
        query: (payload) => {
          const { token, clientID, forID, field, value } = payload;
          return {
            url: "/",
            body: { clientID, forID, field, value },
            method: "PATCH",
            headers: { Authorization: `Bearer ${token}` },
          };
        },
      }),
    };
  },
});

export { clientsApi };
export const {
  useFetchUsersClientsQuery,
  useFetchCoilelClientsQuery,
  useFetchClientByIDQuery,
  useUpdateClientMutation,
  useFetchCharityQuery,
} = clientsApi;

