import { useFetchUsersQuery } from "../store";
import { Box, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import FilterableTable from "./features/Dashboard/FilterableTable";

function Users() {
  const { cid } = useSelector((state) => state.user);
  // const { data = [], error, isLoading, isFetching } = useFetchUsersQuery();
  const error = false;
  const isLoading = false;
  const isFetching = false;
  const data = [{ email: 1 }, { email: 1 }, { email: 1 }, { email: 1 }, { email: 1 }];
  const columns = [
    { header: "Email", accessorKey: "email", enableEditing: false },
    {
      header: "Verified",
      id: "verified",
      accessorFn: (originalRow) => (originalRow.verified ? "Yes" : "No"),
      enableEditing: false,
      size: 80,
      filterVariant: "select",
      filterSelectOptions: ["Yes", "No"],
    },
    {
      header: "Status",
      accessorKey: "status",
      size: 80,
      editVariant: "select",
      filterVariant: "select",
      filterSelectOptions: ["enabled", "disabled"],
      editSelectOptions: ["enabled", "disabled"],
    },
    { header: "last Access", accessorKey: "lastAccess", enableEditing: false, size: 80 },
  ];

  //UPDATE action
  const handleSaveUser = async ({ values, exitEditingMode, row }) => {
    //await updateUser(values);
    exitEditingMode();
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      //deleteUser(row.original.id);
    }
  };

  const options = {
    editDisplayMode: "row",
    enableEditing: true,
    muiToolbarAlertBannerProps: error
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    //onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),

    positionActionsColumn: "last",
  };

  const state = {
    isLoading: isLoading,
    showAlertBanner: error,
    showProgressBars: isFetching,
  };
  return (
    <>
      <div>You can disable or delete a user from here</div>
      <FilterableTable data={data} columns={columns} options={options} state={state} />
    </>
  );
}

export default Users;
