// src/components/DocumentPage.js
import React, { useState } from 'react';
import { Container, Grid, Typography, Paper } from '@mui/material';
import './css/myDocuments.css';
import FileUploader from '../components/features/MyDocuments/FileUploader';
import DocumentViewer from '../components/features/MyDocuments/DocumentViewer';
import { useSelector } from 'react-redux';
import { useUploadDocMutation } from '../store/apis/documentApi';
import { toast } from 'react-toastify';
import DocumentList from '../components/features/MyDocuments/DocumentList';

const MyDocuments = () => {

    const [file, setFile] = useState(null);
    const [tryUload, setTryUpload] = useState(false);


    const token = useSelector((state) => state.user.token);
    const selectedClient = useSelector((state) => state.user.selectedClient);
    const client = useSelector((state) => state.user.clients[selectedClient]);

    const [uploadDoc] = useUploadDocMutation()

    const handleFileUpload = (uploadedFile) => {
        setFile(uploadedFile);
    };

    const handleStoreFile = async () => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('permission', "Owner");

            try {
                await uploadDoc({ fileData: formData, token, clientID: client.ID })
                toast.success("File uploaded successfully.")
                setFile(null)
            } catch (err) {
                toast.error("There was an server side error!")
            }
        } else {
            console.error('No file selected for upload.');
            toast.error("No file selected for upload!")
        }
    };

    return (
        <Container maxWidth="md" className="document-page-container">
            <Typography variant="h4" className="page-title">Document Management</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className="paper-container">
                        <FileUploader onFileUpload={handleFileUpload} handleStoreFile={handleStoreFile} setTryUpload={setTryUpload} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className="paper-container">
                        <DocumentViewer file={file} tryUload={tryUload} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className="paper-container">
                        <DocumentList />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default MyDocuments;





