import React from 'react';

const Grants = () => {
    return (
        <div style={{minHeight: "100vh"}}>
            <h1>This is Grants Page!</h1>
        </div>
    );
};

export default Grants;