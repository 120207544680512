import React from 'react';

const Recurring = () => {
    return (
        <div style={{minHeight: "100vh"}}>
            <h1>This is Recurring page!</h1>
        </div>
    );
};

export default Recurring;