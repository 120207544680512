import { Outlet } from "react-router-dom";
import Header from "../shared/Header";
import Footer from "../shared/Footer";

export default function Root() {
  return (
    <section style={{margin: "0", padding: "0"}}>
      <Header />
      <Outlet />
      <Footer />
    </section>
  );
}
