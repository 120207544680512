import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const PasswordResetConfirmation = () => {
    return (
        <Container maxWidth="sm">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                textAlign="center"
            >
                <CheckCircleOutlineIcon color="success" style={{ fontSize: 80 }} />
                <Typography variant="h4" component="h1" gutterBottom>
                    Password Recovery Email Sent
                </Typography>
                <Typography variant="body1" color="textSecondary" paragraph>
                    If an account exists with the email you provided, you will receive a password recovery email shortly.
                    Please check your inbox and follow the instructions to reset your password.
                </Typography>
                <Button variant="contained" color="primary" href="/login">
                    Back to Login
                </Button>
            </Box>
        </Container>
    );
};

export default PasswordResetConfirmation;
